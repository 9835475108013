import '@fontsource/roboto/latin-400.css';
import '@fontsource/roboto/latin-ext-400.css';
import '@fontsource/roboto/latin-500.css';
import '@fontsource/roboto/latin-ext-500.css';
import '@fontsource/roboto/latin-700.css';
import '@fontsource/roboto/latin-ext-700.css';
import '@fontsource/libre-franklin/latin-400.css';
import '@fontsource/libre-franklin/latin-ext-400.css';
import '@fontsource/libre-franklin/latin-700.css';
import '@fontsource/libre-franklin/latin-ext-700.css';
import '@fontsource/libre-franklin/latin-900.css';
import '@fontsource/libre-franklin/latin-ext-900.css';

const styles = {
    'html body .app-container': {
        height: '100%',
    },

    'html, body': {
        height: '100%',
    },

    /*
     * Prevent `sub` and `sup` elements from affecting the line height in all browsers.
     */
    'sub,sup': {
        fontSize: '75%',
        lineHeight: '0',
        position: 'relative',
        verticalAlign: 'baseline',
    },

    sub: {
        bottom: '-0.25em',
    },

    sup: {
        top: '-0.5em',
    },

    pre: {
        fontFamily: 'inherit',
    },

    /*
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
    '[type="number"]': {
        '-moz-appearance': 'textfield',
        appearance: 'none',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
        },
    },

    /*
     * 1. Disable animations if the client prefers reduced motion. This has been mainly added, to make visual testing
     *    easier.
     * https://www.inovex.de/de/blog/removing-animations-people-troubled-motion-css-angular/
     */
    '@media (prefers-reduced-motion)': {
        '*, *::after, *::before': {
            animationDuration: '1ms !important',
            animationDelay: '-1ms !important',
            animationIterationCount: '1 !important',
            transitionDuration: '1ms !important',
            transitionDelay: '-1ms !important',
        },
    },
};

export default styles;
