import { merge } from 'lodash';
import { Transforms } from 'slate';

import { BLOCK_LOADING_STATES, BLOCK_META_STATES } from '@@editor/constants';
import { Editor, Node, ReactEditor } from '@@editor/helpers';
import { UpdateEmbedData } from '@@editor/typings/Embed';
import { ImageElement } from '@@editor/helpers/Element';
import { validateImageFile } from '@@form/utils/validators/image';
import { AssetPayload } from '@@utils/assets';
import { UploadFile } from '@@api/hooks/useUploadFile';

type Options = {
    updateEmbedData?: UpdateEmbedData;
    uploadFile: UploadFile;
    updateSource?: boolean;
};

export const uploadFileHelper =
    (options: Options) =>
    async (
        editor: Editor,
        block: Node,
        file: File | string | AssetPayload,
        previousNode?: Node,
    ) => {
        const { updateEmbedData = (data) => data, updateSource = true } = options;

        const path = ReactEditor.findPath(editor, block);

        const updateBlockDataByKey = (data) => {
            Transforms.setNodes(editor, merge({}, block, { data }), { at: path });
        };

        const errors = await validateImageFile(file);

        if (errors.length === 0) {
            return options
                .uploadFile({
                    file,
                    onUploadProgress: (progressEvent) => {
                        updateBlockDataByKey({
                            [BLOCK_META_STATES.PROGRESS]: progressEvent.lengthComputable
                                ? { value: progressEvent.loaded, max: progressEvent.total }
                                : { value: 0, max: progressEvent.total },
                            [BLOCK_META_STATES.LOADING]: true,
                            [BLOCK_META_STATES.LOADING_STATE]: BLOCK_LOADING_STATES.UPLOAD_FILE,
                        });
                    },
                })
                .then((payload) => {
                    const { id, title, _links, mediaType, metadata, height, width } = payload.body;
                    const payloadSrc = _links?.data.href;
                    const { src, mimetype, originalSrc } = (block as ImageElement).data;
                    const { caption, credit, name } = metadata;

                    updateBlockDataByKey({
                        mimetype: mimetype || mediaType,
                        src: updateSource ? payloadSrc : src,
                        originalSrc: typeof originalSrc === 'string' ? originalSrc : null,
                        embed: updateEmbedData({
                            src: payloadSrc,
                            id,
                            title,
                            caption,
                            credit,
                            name,
                            naturalHeight: height,
                            naturalWidth: width,
                        }),
                        [BLOCK_META_STATES.LOADING]: true,
                        [BLOCK_META_STATES.LOADING_STATE]: BLOCK_LOADING_STATES.LOAD_FILE,
                    });
                })
                .catch(() => {
                    // Fail more gracefully. If we did not intend to update the source
                    // it could still be useful, so we should not just delete everything here
                    if (updateSource) {
                        Transforms.removeNodes(editor, { at: path });
                    } else {
                        updateBlockDataByKey({
                            ...(previousNode?.data || {}),
                            [BLOCK_META_STATES.LOADING]: false,
                            [BLOCK_META_STATES.LOADING_STATE]: undefined,
                        });
                    }
                });
        }

        if (updateSource) {
            Transforms.removeNodes(editor, { at: path });
        } else {
            updateBlockDataByKey({
                ...(previousNode?.data || {}),
                [BLOCK_META_STATES.LOADING]: false,
                [BLOCK_META_STATES.LOADING_STATE]: undefined,
            });
        }

        return Promise.resolve();
    };
