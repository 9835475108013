// This is the only place in this stack where we use `HistoryEditor` directly! Use this helper
// in all other places.
// eslint-disable-next-line no-restricted-imports
import { History, HistoryEditor as SlateHistoryEditor } from 'slate-history';
import { BaseSelection } from 'slate';

import { Editor } from './Editor';
import { Operation } from './Operation';

export type HistoryEditor = SlateHistoryEditor;

interface IHistoryEditor {
    asSingleHistoryEntry: (editor: Editor, fn: VoidFunction) => void;
}

type Batch = {
    operations: Operation[];
    selectionBefore: BaseSelection | null;
};

const recordOperations = (editor: Editor, fn: VoidFunction) => {
    const { apply } = editor;
    const batch: Batch = { operations: [], selectionBefore: editor.selection };

    Object.assign(editor, {
        apply: (op: Operation) => {
            batch.operations.push(op);

            return apply(op);
        },
    });

    fn();

    Object.assign(editor, { apply });

    return batch;
};

const removePreviousOperations = (history: History, { type }: { type: 'set_selection' }): void => {
    const { undos } = history;

    while (true) {
        const lastBatch = undos && undos[undos.length - 1];
        const lastOp = lastBatch && lastBatch.operations[lastBatch.operations.length - 1];

        if (lastOp && lastOp.type === type) {
            if (
                lastBatch.operations.length !== 0 &&
                typeof lastBatch.operations.pop() === 'undefined'
            ) {
                // `lastBatch.operations` is not modifiable
                break;
            } else if (lastBatch.operations.length === 0) {
                // `lastBatch.operations` is empty, we do not want to keep empty batches
                undos.pop();

                if (undos.length <= 0) {
                    // If no `undos` left, no need to continue loop
                    break;
                }
            }
        } else {
            // If last operation does not exist or does not match the requested type,
            // do not continue removal
            break;
        }
    }
};

export const HistoryEditor: typeof SlateHistoryEditor & IHistoryEditor = {
    ...SlateHistoryEditor,

    asSingleHistoryEntry: (editor, fn) => {
        const { history, writeHistory } = editor;

        // Tell `slate-history` to not save any operations in history, because we'll do
        // it ourselves
        HistoryEditor.withoutSaving(editor, () => {
            Editor.withoutNormalizing(editor, () => {
                // Record operations that are executed within `fn`
                const batch = recordOperations(editor, fn);

                if (batch.operations.length) {
                    // If the first operation is a `set_selection` operation, remove any previous
                    // `set_selection` operations since we do not want them in order to preserve a
                    // meaningful history.
                    if (batch.operations[0].type === 'set_selection') {
                        removePreviousOperations(history, { type: 'set_selection' });
                    }

                    // Prevent 'slate-history' from manipulating this history entry. If `slate-history`
                    // wants to add operations to this batch, create a new history entry.
                    batch.operations.pop = (): Operation | undefined => undefined;
                    batch.operations.push = (...args): number => {
                        writeHistory('undos', { ...batch, operations: args });

                        return args.length;
                    };

                    // Add all recorded oprations as a single history entry to the history
                    writeHistory('undos', batch);
                }
            });
        });
    },
};
