import { mergeWith } from 'lodash';

export type MergeWithPathCustomizer = (
    objValue: unknown,
    srcValue: unknown,
    key: string,
    object: unknown,
    source: unknown,
    stack: unknown,
    path: string,
) => unknown;

export const mergeWithPath = (...args: unknown[]): ReturnType<typeof mergeWith> => {
    const objects = args.slice(0, -1);
    const customizer = args.pop() as MergeWithPathCustomizer;
    const internalStack: { source: unknown; path: string[] }[] = [];

    return mergeWith(
        // `mergeWith` can take an infinite array for sources, but the typescript implementation of it limits it to 4. this gives us
        // troubles here, since we want to stay as flexible as possible. let's disable typescript to make it work
        // @ts-expect-error
        ...objects,
        // eslint-disable-next-line max-params
        (objValue, srcValue, key: string, object, source, stack) => {
            let path: string[] = [];

            while (true) {
                if (!internalStack.length) {
                    internalStack.push({ source, path: [] });
                }

                const prev = internalStack[internalStack.length - 1];

                if (source === prev.source) {
                    path = prev.path.concat(key);
                    internalStack.push({ source: srcValue, path });
                    break;
                }

                internalStack.pop();
            }

            return customizer(objValue, srcValue, key, object, source, stack, path.join('.'));
        },
    );
};
