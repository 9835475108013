import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import React, { useCallback } from 'react';

import getImagesFromDataTransfer from '@@utils/dataTransfer/getImagesFromDataTransfer';
import getUrlsFromDataTransfer from '@@utils/dataTransfer/getUrlsFromDataTransfer';
import { ImageMimeType } from '@@constants/ImageMimeTypes';
import { stripUnsupportedFiles } from '@@utils/fileUpload';
import snackbar from '@@containers/Snackbar';

type Props = {
    allowedMimeTypes?: ImageMimeType[];
    multiple?: boolean;
    onDrop: (files) => void;
    onUpload: (files) => void;
    acceptTypes?: string[];
    isLoading?: boolean;
};

const useDropArea = (
    props: Props,
): [
    Omit<Props, 'onUpload'> & {
        canDrop: boolean;
        isOver: boolean;
        onUpload: (e: Event, files) => void;
    },
    React.RefCallback<HTMLDivElement>,
] => {
    const {
        allowedMimeTypes = [],
        multiple,
        onDrop,
        onUpload,
        acceptTypes = [NativeTypes.FILE, NativeTypes.URL, NativeTypes.HTML, NativeTypes.TEXT],
        isLoading,
    } = props;

    const handleUpload = useCallback(
        (e, files) => {
            const supportedFiles = stripUnsupportedFiles(files, allowedMimeTypes);

            onUpload(supportedFiles.slice(0, multiple ? supportedFiles.length : 1));
        },
        [allowedMimeTypes, multiple, onUpload],
    );

    const [dropAreaProps, drop] = useDrop({
        accept: acceptTypes,
        canDrop: () => !isLoading,
        drop(item) {
            // @ts-expect-error
            const html = item.dataTransfer?.getData('text/html') || item.html;
            // @ts-expect-error
            const urls = item.dataTransfer ? getUrlsFromDataTransfer(item.dataTransfer) : item.urls;
            // @ts-expect-error
            const { files, text, attachment } = item;
            const images = getImagesFromDataTransfer(
                { files, html, text, urls, attachment },
                allowedMimeTypes,
                (error) => {
                    if (error) {
                        snackbar.error(error.message, {
                            title: error.name,
                        });
                    }
                },
            );

            if (images.length) {
                onDrop(images.slice(0, multiple ? images.length : 1));
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    return [{ ...props, ...dropAreaProps, onUpload: handleUpload }, drop];
};

export default useDropArea;
