import { useTranslation } from 'react-i18next';
import React from 'react';
import { styled } from '@mui/material';

import { Element } from '@@editor/helpers';
import { UnityState } from '@@editor/typings/UnityElements';
import makeState from '@@editor/utils/makeState';
import QuoteEditorField from '@@form/fields/RichTextEditor/QuoteEditor';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { UseReactHookFormProps } from '@@containers/ReactHookForm/types';

type FormValues = {
    quote: UnityState | null;
    caption: UnityState | null;
};

type Props = {
    type: string;
    element: Element;
    className?: string;
    renderButtons: () => React.ReactNode;
    onCancel: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit'];
};

export const QuoteForm = ({ onCancel, onSubmit, renderButtons, element, className }: Props) => {
    const { t } = useTranslation();

    const handleClickOnCancel = (): void => {
        onCancel();
    };

    // @ts-expect-error
    const initialValues: FormValues = element?.data || {
        quote: makeState(),
        caption: makeState(),
    };

    return (
        <ReactHookForm
            formName="LayoutQuote"
            values={initialValues}
            onSubmit={onSubmit}
            onCancel={handleClickOnCancel}
            renderButtons={renderButtons}
            hasCancelButton
            hasActiveCancelButton
            alwaysShowCancelButton
            className={className}
        >
            <QuoteEditorField
                name="quote"
                label={t('editor.plugin.quote.form.quote')}
                minRows={3}
                required
                inputProps={{
                    singleLine: true,
                }}
            />

            <QuoteEditorField
                name="caption"
                label={t('editor.plugin.quote.form.label')}
                inputProps={{
                    singleLine: true,
                }}
            />
        </ReactHookForm>
    );
};

const ComposedQuoteForm = styled(QuoteForm)({
    width: '640px',
});

export default ComposedQuoteForm;
