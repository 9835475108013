import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';

import { getDefaultTeaserVariant } from '@@containers/Teaser/utils';
import showPreview from '@@utils/showPreview';
import linkToContent from '@@containers/ContentItem/utils/linkToContent';
import { useMetadataClient } from '@@api/services/metadata/client';
import ContentItemWithActions from '@@containers/ContentItem/ContentItemWithActions';

const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: 'transparent',
    borderLeft: `3px solid ${theme.palette.primary.light}`,
    padding: theme.spacing(1, 3),
    margin: theme.spacing(2, 0),
}));

const Title = styled('div')(({ theme }) => ({
    ...theme.typography.editorPrimarySmall,
    fontWeight: 'bold',
}));

const TitleHeader = styled('div')(({ theme }) => ({
    ...theme.typography.editorPrimarySmall,
}));

type Props = {
    metadataId: number;
    onChange: (value: number[]) => void;
    readOnly?: boolean;
    value: number[];
};

const TeaserItem = ({ metadataId, onChange, readOnly, value }: Props) => {
    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();

    const params = { id: metadataId };

    const { data: metadata, isLoading } = metadataClient.metadata.get.useQuery({
        queryKey: metadataKeys.metadata.get({ params, query: {} }),
        queryData: { params },
    });

    const data = metadata?.body;
    const navigate = useNavigate();

    const handleOnPreview = () => {
        showPreview(metadataId);
    };

    const handleOnEdit = () => {
        if (data) {
            linkToContent(data, navigate, true);
        }
    };

    const handleOnDelete = () => {
        onChange(value.filter((itemId) => itemId !== metadataId));
    };

    const teaser = getDefaultTeaserVariant(data?.teasers);

    return !isLoading && teaser ? (
        <ContentItemWithActions
            onDeleteClick={handleOnDelete}
            onEditClick={handleOnEdit}
            onPreviewClick={handleOnPreview}
            readOnly={readOnly}
        >
            <Wrapper>
                <TitleHeader>{teaser.titleHeader}</TitleHeader>
                <Title>{teaser.title}</Title>
            </Wrapper>
        </ContentItemWithActions>
    ) : null;
};

export default TeaserItem;
