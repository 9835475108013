export enum ContentStatus {
    DRAFT = 'draft',
    REVIEW_1 = 'review1',
    SEO = 'seo',
    REVIEW_2 = 'review2',
    PROOFREAD = 'proofread',
    REQUIRED_REWORK = 'requiredRework',
    READY_TO_PUBLISH = 'readyToPublish',
}

export default ContentStatus;
