import isURL from 'validator/lib/isURL';

export const isSecureUrl = (value: string | null | undefined) =>
    isURL(value || '', {
        // eslint-disable-next-line camelcase
        require_protocol: true,
        protocols: ['https'],
    });

export const splitDataUri = (dataUri) => {
    const [uri, mimeType, data] = dataUri.match(/^data:([^;]+);base64,(.*)$/);

    return {
        uri,
        mimeType,
        data,
    };
};

export const getUrlParameterByName = (name, url = window.location.href) => {
    // @ts-expect-error
    if (url?.searchParams && url instanceof URL) {
        return url.searchParams.get('href');
    }

    const prefixedName = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${prefixedName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const parseUrl = (string) => {
    try {
        if (/^\/\/[^/]/.test(string)) {
            return new URL(window.location.protocol + string);
        }

        return new URL(string);
    } catch (ex) {
        if (process.env.NODE_ENV === 'development') {
            console.warn('Could not parse url: ' + string);
        }

        return;
    }
};

export const toURL = (value) => (value instanceof URL ? value : parseUrl(value));
