import { Box, Fade, styled } from '@mui/material';
import React from 'react';

import { DeleteButton, PreviewButton, EditButton } from './ContentItemActions';
import { ContentItemActions } from './styles';

type Props = React.PropsWithChildren<{
    onPreviewClick?: () => void;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
    readOnly?: boolean;
    position?: 'top' | 'bottom';
}>;

const Wrapper = styled(Box)({
    position: 'relative',
    width: '100%',
});

const ContentItemWithActions = React.forwardRef<HTMLDivElement, Props>(
    (
        { children, onPreviewClick, onEditClick, onDeleteClick, readOnly, position = 'bottom' },
        ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
        const [isHovered, setIsHovered] = React.useState(false);

        return (
            <Wrapper
                ref={ref}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {children}

                <Fade in={isHovered} unmountOnExit mountOnEnter>
                    <ContentItemActions $position={position}>
                        {onPreviewClick && <PreviewButton onClick={onPreviewClick} />}
                        {onEditClick && <EditButton onClick={onEditClick} />}
                        {onDeleteClick && !readOnly && <DeleteButton onClick={onDeleteClick} />}
                    </ContentItemActions>
                </Fade>
            </Wrapper>
        );
    },
);

export default ContentItemWithActions;
