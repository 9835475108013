import apiEnvs from './../../api.envs.json';
import envs from './../../envs.json';

declare global {
    interface Window {
        runtime: {
            env: {
                CDII_ENV?: string;
            };
        };
    }
}

type IApiEnvsConfig = Record<string, IApiEnvConfig>;

type IEnvsConfig = Record<string, IEnvConfig>;

interface IResolvedEnv {
    devToolbarDisabled: boolean;
    googleAnalyticsTrackingId: string | null;
    env: string;
    apiEnv: string;
    apiEnvVars: IApiEnvConfig;
    apiEnvs: IApiEnvsConfig;
    nodeEnv: string | undefined;
}

// We have to declare these two variables to be able to type them
const typedEnvs: IEnvsConfig = envs;
const typedApiEnvs: IApiEnvsConfig = apiEnvs;

const env: string = window.runtime?.env?.CDII_ENV || process.env.CDII_ENV || process.env.NODE_ENV!;
const envConfig = typedEnvs[env];

// Memory and localStorage take priority over this setting
export const resolveApiEnv = (apiEnv?: string | null): string =>
    apiEnv || localStorage.getItem('CDII_API') || process.env.CDII_API || envConfig.apiEnv;

const resolveEnv = (_apiEnv_?: string): IResolvedEnv => {
    const apiEnv = resolveApiEnv(_apiEnv_);
    const apiEnvVars = typedApiEnvs[apiEnv];

    if (!apiEnvVars) {
        throw new Error(`No matching configuration found for CDII_API=${apiEnv} in api.envs.json`);
    }

    return {
        // devToolbar is enabled by default (besides visual tests)
        // disable it when needed, e.g. demo/production env
        devToolbarDisabled: envConfig.devToolbarDisabled,
        googleAnalyticsTrackingId: envConfig.googleAnalyticsTrackingId,
        // To which environment is it deployed?
        // E.g. dev/staging/demo/production and test
        env,
        // What backend API is connected?
        // E.g. localhost/mock-server/a*/dev-unitycms.io/...
        apiEnv,
        apiEnvVars,
        apiEnvs,
        // Replaced during build time
        // E.g. production (optimized) vs development build
        nodeEnv: process.env.NODE_ENV,
    };
};

export default resolveEnv;
