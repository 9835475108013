import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@mui/material';

import { usePageLayout } from '@@containers/App/PageLayout/PageLayoutContext';
import ButtonGroup from '@@form/components/ButtonGroup';

type MenuBarProps = PropsWithChildren<{
    reverse?: boolean;
}>;

export type PageHeaderMenuBarProps = MenuBarProps;

type PageFooterMenuBarProps = MenuBarProps;

const MenuBar = styled('div', {
    shouldForwardProp: (prop) => prop !== 'reverse',
})<MenuBarProps>((props) => ({
    display: 'flex',
    flex: `1 0 ${props.theme.fixed.pageHeader.height}`,
    flexDirection: props.reverse ? 'row-reverse' : 'row',
    alignItems: 'center',
    width: '100%',
    minHeight: props.theme.fixed.pageHeader.height,
    padding: `0 ${props.theme.spacing(4)}`,
    backgroundColor: props.theme.palette.common.white,
    borderTop: `1px solid ${props.theme.palette.primary['100']}`,
    borderBottom: `1px solid ${props.theme.palette.primary['100']}`,
})) as typeof MenuBar;

const PageHeaderMenuBar = (props: PageHeaderMenuBarProps) => {
    const { head } = usePageLayout();

    const header = <MenuBar as="header" {...props} />;

    return head.current ? ReactDOM.createPortal(header, head.current) : header;
};

// Bottom menu bar that has to be unique per page
// as it can affect css of other elements, like right-side toolbar
const PageFooterMenuBar = (props: PageFooterMenuBarProps) => {
    const { foot } = usePageLayout();

    const footer = <MenuBar as="footer" {...props} />;

    return foot.current ? ReactDOM.createPortal(footer, foot.current) : footer;
};

const StyledButtonGroup = styled(ButtonGroup)({
    height: '100%',
    flexWrap: 'wrap',
});

MenuBar.PageHeader = PageHeaderMenuBar;
MenuBar.PageFooter = PageFooterMenuBar;
MenuBar.ButtonGroup = StyledButtonGroup;

export default MenuBar;
