import { z } from 'zod';

import { DEFAULT_TEASER_ID, VARIANTS_KEY } from '@@containers/Teaser/constants';
import { AUTHOR_TYPES } from '@@containers/metadata/components/Author/constants';

import {
    ContentStatus,
    Creator,
    Entity,
    ImageWithoutUrl,
    PublicationStatus,
    RichText,
    UnityRichText,
} from '../../utils/schemas/schemas';
import { unityVariantsShape, variantsShape } from '../../utils/schemas/utils';

export const AdditionalSettings = z.object({
    premium: z.boolean(),
    breakingNews: z.boolean(),
    live: z.boolean(),
    contest: z.boolean(),
    podcast: z.boolean(),
    renderedInWebView: z.boolean(),
    opinion: z.boolean(),
    adsEnabled: z.boolean(),
    newsletterSubscribingEnabled: z.boolean(),
    ratingEnabled: z.boolean(),
    feedbackEnabled: z.boolean(),
    sharingEnabled: z.boolean(),
    likesEnabled: z.boolean(),
    commentingEnabled: z.boolean(),
    videoInContent: z.boolean(),
    commentRisk: z.union([z.literal('low'), z.literal('high')]).optional(),
    allowRobots: z.boolean(),
    publicationDateExposed: z.boolean(),
    visibleInNewestContent: z.boolean(),
    authorBiographyDisplayed: z.boolean(),
    headDisabled: z.boolean(),
    readAloudEnabled: z.boolean(),
    shortNewsEnabled: z.boolean(),
    opinionType: z.string().nullable(),
    syndication: z.boolean(),
    syndicationChangeDisabled: z.boolean(),
    translation: z.boolean(),
    translationChangeDisabled: z.boolean(),
});

const AuthorBase = z.object({
    id: z.number().optional(),
    manualType: z.boolean().optional(),
    info: z.string().nullish(),
});

const UserAuthor = AuthorBase.extend({
    type: z.literal(AUTHOR_TYPES.USER),
    user: z.object({
        id: z.string().nullish(),
        name: z.string().nullish(),
    }),
});

const ContributorAuthor = AuthorBase.extend({
    type: z.literal(AUTHOR_TYPES.CONTRIBUTOR),
    user: z.object({
        id: z.string().nullish(),
        name: z.string().nullish(),
    }),
});

const ServiceUserAuthor = AuthorBase.extend({
    type: z.literal(AUTHOR_TYPES.SERVICE_USER),
    user: z.object({
        id: z.string().nullish(),
        name: z.string().nullish(),
    }),
});

const AgencyAuthor = AuthorBase.extend({
    type: z.literal(AUTHOR_TYPES.AGENCY),
    agency: z.object({
        id: z.string().nullish(),
        name: z.string().nullish(),
    }),
});

const Author = z.union([UserAuthor, ContributorAuthor, ServiceUserAuthor, AgencyAuthor]);

export type Author = z.infer<typeof Author>;

const UnityAuthorBase = z.object({
    id: z.number().optional(),
    info: z.string().nullish(),
    name: z.string().nullable(),
});

const UnityUserAuthor = UnityAuthorBase.extend({
    type: z.union([z.literal(AUTHOR_TYPES.USER), z.literal(AUTHOR_TYPES.MANUAL_USER)]),
    userId: z.string().nullish(),
});

const UnityContributorAuthor = UnityAuthorBase.extend({
    type: z.literal(AUTHOR_TYPES.CONTRIBUTOR),
    userId: z.string().nullish(),
});

const UnityServiceUserAuthor = UnityAuthorBase.extend({
    type: z.literal(AUTHOR_TYPES.SERVICE_USER),
    userId: z.string().nullish(),
});

const UnityAgencyAuthor = UnityAuthorBase.extend({
    type: z.union([z.literal(AUTHOR_TYPES.AGENCY), z.literal(AUTHOR_TYPES.MANUAL_AGENCY)]),
    agencyId: z.string().nullish(),
});

const UnityAuthor = z.union([
    UnityUserAuthor,
    UnityContributorAuthor,
    UnityServiceUserAuthor,
    UnityAgencyAuthor,
]);

export type UnityAuthor = z.infer<typeof UnityAuthor>;

const SeoTeasers = z.object({
    allowAutomaticUpdate: z.boolean().optional(),
    lead: z.string().nullish(),
    title: z.string().nullish(),
});

const Teaser = z.object({
    titleHeader: z.string().nullish(),
    title: z.string().nullish(),
    lead: z.string().nullish(),
    image: ImageWithoutUrl,
    tenantIds: z.array(z.number()).optional(),
    allowAutomaticUpdate: z.boolean().optional(),
});

const DefaultTeaser = Teaser.extend({
    useEmbedUrl: z.boolean().optional(),
    embedUrl: z.string().optional(),
});

const Opengraph = Teaser.extend({
    allowAutomaticUpdate: z.boolean().optional(),
});

const SocialTeasers = z.object({
    facebook: Teaser,
    twitter: Teaser,
});

const OpengraphTeasers = z.object({
    opengraph: Opengraph.optional(),
});

const TeaserVariants = z.object({
    [DEFAULT_TEASER_ID]: DefaultTeaser,
});

export type TeaserVariants = z.infer<typeof TeaserVariants>;

/**
 * @deprecated We keep this for backward compatibility with 20min related contents
 */
const TeaserVariantWithSocial = TeaserVariants.extend(SocialTeasers.shape);
const TeaserVariantWithOpengraph = TeaserVariants.extend(OpengraphTeasers.shape);

const Teasers = z.object({
    [VARIANTS_KEY]: z.union([TeaserVariantWithSocial, TeaserVariantWithOpengraph]),
});

const MetadataTeaser = Teasers.extend({
    allowAutomaticUpdate: z.boolean().optional(),
});

const ScheduledPlacing = z.object({
    status: z.boolean(),
    publicationTime: z.string().nullable(),
    expiryTime: z.string().nullable(),
});

export type ScheduledPlacing = z.infer<typeof ScheduledPlacing>;

const RelatedContent = z.object({
    contentIds: z.array(z.number()),
    displayAsBundle: z.boolean(),
});

const MetadataPlacement = z.object({
    categories: z.array(z.number()),
    categoryPlacementDetails: z
        .record(
            z.string(),
            z.object({
                position: z.number().optional(),
            }),
        )
        .optional(),
    mainCategory: z.number().nullable(),
});

const UrlSlug = z.object({
    suggested: z.string(),
    manual: z.string().nullish(),
});

export type UrlSlug = z.infer<typeof UrlSlug>;

const InfoboxBase = z.object({
    id: z.number().optional(),
    title: z.string(),
});

const UnityInfobox = InfoboxBase.extend({
    content: UnityRichText,
});

export type UnityInfobox = z.infer<typeof UnityInfobox>;

const Infobox = InfoboxBase.extend({
    content: RichText,
});

export type Infobox = z.infer<typeof Infobox>;

export const PrintMetadata = z.object({
    metadataId: z.number(),
    publicationTargetDate: z.string().nullable(),
    pageName: z.string().nullable(),
    category: z.string().nullable(),
    publication: z.string().nullable(),
    type: z.string().nullable(),
    studioStatus: z.string().nullable(),
    studioDossierId: z.string().nullable(),
    desknetSyncEnabled: z.boolean().nullable(),
    desknetElementId: z.string().nullable(),
    studioSyncEnabled: z.boolean().nullable(),
});

const KickwordVariant = z.object({
    backgroundColorCode: z.string().nullable(),
    borderColorCode: z.string().nullable(),
    icon: z.any(),
    textColorCode: z.string().nullable(),
});

const KickwordBase = Entity.extend({
    id: z.number().optional(),
    name: z.string().optional(),
});

export const UnityKickword = KickwordBase.extend({
    variants: unityVariantsShape(KickwordVariant),
});

export type UnityKickword = z.infer<typeof UnityKickword>;

export const Kickword = KickwordBase.extend({
    variants: variantsShape(KickwordVariant),
});

export type Kickword = z.infer<typeof Kickword>;

export const NewKickword = Kickword.omit({ id: true });
export type NewKickword = z.infer<typeof NewKickword>;

export const NewUnityKickword = UnityKickword.omit({ id: true });
export type NewUnityKickword = z.infer<typeof NewUnityKickword>;

const MetadataKickword = z.object({ id: z.number().nullable() });
const MetadataBase = Entity.extend({
    actualTextLength: z.number().nullish(),
    id: z.number(),
    bookmarkId: z.string().uuid().optional(),
    mainCategoryId: z.number().nullish(),
    contentName: z.string().optional(),
    contentOwner: z.string().nullish(),
    contentStatus: ContentStatus.nullable(),
    creator: Creator.nullish(),
    createdAt: z.string().nullish(),
    updatedAt: z.string().nullish(),
    customPublishedAt: z.string().nullable(),
    customUpdatedAt: z.string().nullable(),
    externalId: z.string().nullish(),
    externalSystem: z.string().nullable(),
    lastUpdatedBy: Creator.nullish(),
    legacyCanonicalUrls: z.record(z.unknown()).optional(),
    legacyExportIds: z.record(z.unknown()).optional(),
    legacyIds: z.record(z.unknown()).optional(),
    locale: z.string().nullable(),
    mainTenantId: z.number().nullish(),
    publicationStatus: PublicationStatus.nullable(),
    publishedAt: z.string().nullish(),
    previewUrls: z.record(z.string()).optional(),
    readyToPublishAt: z.string().nullish(),
    resourceId: z.string(),
    resourceName: z.string().optional(),
    scheduledPlacing: ScheduledPlacing.nullable(),
    sourceMetadataId: z.number().nullable(),
    tags: z.array(z.number()).optional(),
    targetDateTime: z.string().nullable(),
    tenantIds: z.array(z.number()),
    _links: z
        .object({
            externalUrl: z
                .object({
                    href: z.string(),
                })
                .optional(),
        })
        .optional(),
    printMetadata: PrintMetadata.nullish(),
    targetTextLength: z.number().nullish(),
    workflowChecklist: z.object({
        seoDone: z.boolean(),
        photoeditingDone: z.boolean(),
        proofreadingDone: z.boolean(),
        productionDone: z.boolean(),
    }),
});

export const Metadata = MetadataBase.extend({
    additionalSettings: variantsShape(AdditionalSettings).optional(),
    authors: z.array(Author),
    infobox: variantsShape({ infoboxes: z.array(Infobox) }).optional(),
    kickword: variantsShape({ kickword: MetadataKickword.nullable() }).optional(),
    note: RichText.optional(),
    placement: variantsShape(MetadataPlacement).optional(),
    relatedContent: variantsShape(RelatedContent).optional(),
    seoTeasers: variantsShape(SeoTeasers),
    styles: variantsShape({ style: z.string() }).optional(),
    targetDate: z.string().nullable(),
    targetTime: z.string().nullable(),
    teasers: variantsShape(MetadataTeaser),
    urlSlugs: variantsShape(UrlSlug).optional(),
});

export type Metadata = z.infer<typeof Metadata>;

export const EmbeddedMetadata = Metadata.pick({
    id: true,
    resourceId: true,
    resourceName: true,
    sourceMetadataId: true,
    contentName: true,
    externalId: true,
    externalSystem: true,
    locale: true,
    creator: true,
    lastUpdatedBy: true,
    tenantIds: true,
    mainCategoryId: true,
    publicationStatus: true,
    contentStatus: true,
    createdAt: true,
    updatedAt: true,
    teasers: true,
    placement: true,
    printMetadata: true,
    scheduledPlacing: true,
    targetDateTime: true,
    mainTenantId: true,
    additionalSettings: true,
    authors: true,
    actualTextLength: true,
});

export type EmbeddedMetadata = z.infer<typeof EmbeddedMetadata>;

export const UnityMetadata = MetadataBase.extend({
    additionalSettings: unityVariantsShape(AdditionalSettings).nullish(),
    authors: z.array(UnityAuthor).nullable(),
    infobox: unityVariantsShape(z.array(UnityInfobox)).optional(),
    kickword: z.record(z.string(), z.union([MetadataKickword, z.null()])).optional(),
    note: UnityRichText.optional(),
    placement: unityVariantsShape(MetadataPlacement).nullish(),
    relatedContent: unityVariantsShape(RelatedContent).optional(),
    seoTeasers: unityVariantsShape(SeoTeasers),
    styles: unityVariantsShape(z.string()).optional(),
    teasers: unityVariantsShape(MetadataTeaser).optional(),
    urlSlugs: unityVariantsShape(UrlSlug).optional(),
});

export type UnityMetadata = z.infer<typeof UnityMetadata>;

export const UnityEmbeddedMetadata = UnityMetadata.pick({
    id: true,
    resourceId: true,
    resourceName: true,
    sourceMetadataId: true,
    contentName: true,
    externalId: true,
    externalSystem: true,
    locale: true,
    creator: true,
    lastUpdatedBy: true,
    tenantIds: true,
    mainCategoryId: true,
    publicationStatus: true,
    contentStatus: true,
    createdAt: true,
    updatedAt: true,
    teasers: true,
    placement: true,
    printMetadata: true,
    scheduledPlacing: true,
    targetDateTime: true,
    mainTenantId: true,
    additionalSettings: true,
    authors: true,
    actualTextLength: true,
});

export type UnityEmbeddedMetadata = z.infer<typeof UnityEmbeddedMetadata>;

export const CreationMetadata = Metadata.pick({
    contentName: true,
    mainCategoryId: true,
    tenantIds: true,
});
export type CreationMetadata = z.infer<typeof CreationMetadata>;

export const FilerepoFile = Entity.extend({
    id: z.string(),
    title: z.string().nullish(),
    mediaType: z.string().nullable(),
    _links: z.object({
        self: z.object({
            href: z.string(),
        }),
        data: z.object({
            href: z.string(),
        }),
    }),
    metadata: z.object({
        name: z.string().nullish(),
        caption: z.string().nullish(),
        credit: z.string().nullish(),
        size: z.string().nullish(),
        weight: z.string().optional(),
        filename: z.string().optional(),
    }),
    height: z.number().nullish(),
    width: z.number().nullish(),
    originalFile: z.string().optional(),
});

export type FilerepoFile = z.infer<typeof FilerepoFile>;

export const Tag = Entity.extend({
    id: z.number(),
    active: z.boolean().optional(),
    description: z.string().nullable(),
    image: z.any(),
    name: z.string(),
    title: z.string().nullable(),
});

export type Tag = z.infer<typeof Tag>;

export const NewTag = Tag.omit({ id: true });
export type NewTag = z.infer<typeof NewTag>;
