import { useTranslation } from 'react-i18next';
import React from 'react';
import { styled } from '@mui/material';

import { Element } from '@@editor/helpers';
import { UnityState } from '@@editor/typings/UnityElements';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import ImageField from '@@form/fields/ImageField';
import RelatedContentField from '@@form/fields/RelatedContent';
import makeState from '@@editor/utils/makeState';
import SimpleRichTextEditorField from '@@form/fields/RichTextEditor/SimpleRichTextEditor';
import { useMatchUrl } from '@@router/useMatchUrl';
import { type Image } from '@@api/utils/schemas/schemas';
import { type Metadata } from '@@api/services/metadata/schemas';
import { UseReactHookFormProps } from '@@containers/ReactHookForm/types';

import { MAX_HEIGHT, MAX_WIDTH } from '../../constants';

const StyledReactHookForm = styled(ReactHookForm)({
    width: '640px',
});

interface IFormValues {
    title: UnityState | null;
    image: Image;
    metadataIds: Metadata['id'][];
}

type Props = {
    type: string;
    element: Element;
    inlineEdited?: boolean;
    className?: string;
    renderButtons: () => React.ReactNode;
    onCancel: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit'];
};

const DynamicTeaserForm = (props: Props) => {
    const { renderButtons, element, onCancel, onSubmit, inlineEdited } = props;

    // @ts-expect-error
    const initialValues: IFormValues = element?.data || {
        title: makeState(),
        image: {
            caption: makeState(),
        },
        metadataIds: [],
    };

    const { t } = useTranslation();
    const matchUrl = useMatchUrl();

    return (
        <StyledReactHookForm
            formName="LayoutDynamicTeaser"
            values={initialValues}
            onSubmit={onSubmit}
            onCancel={onCancel}
            renderButtons={renderButtons}
            hasCancelButton
            hasActiveCancelButton
            alwaysShowCancelButton
        >
            {!inlineEdited && (
                <SimpleRichTextEditorField
                    name="title"
                    label={t('editor.plugin.dynamicTeaser.form.title')}
                />
            )}

            <ImageField
                name="image"
                label={t('editor.plugin.dynamicTeaser.form.image')}
                noDropArea
                flagAsOptional
                imageOnly
                transformations={{
                    maxWidth: MAX_WIDTH,
                    maxHeight: MAX_HEIGHT,
                }}
            />

            {!inlineEdited && (
                <RelatedContentField
                    name="metadataIds"
                    label={t('editor.plugin.dynamicTeaser.form.contents')}
                    useModal
                    allowBundle={false}
                    pathname={`${matchUrl}/relatedContent`}
                    path={`relatedContent`}
                    buttonProps={{
                        iconName: 'plus-sharp',
                        children: t('editor.plugin.dynamicTeaser.form.addContents'),
                    }}
                    modalProps={{
                        multiple: true,
                    }}
                    required
                />
            )}
        </StyledReactHookForm>
    );
};

export default DynamicTeaserForm;
