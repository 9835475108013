/*
 * HOW-TO ADD ICONS
 *
 * Important! Read this before adding an icon!
 *
 * 1. We want to avoid having a lot of custom icons (use what fontawesome offers; also consider combining
 *    fontawesome icons by creating stacked icons!)
 *
 * 2. We want to avoid having duplicate icons (check if an icon is already imported)
 *
 * 3. We want to avoid using too many variations for the same icon (check what styles/families of an icon are
 *    already imported and ask yourself if we can re-use those styles! the value of having less variations might
 *    be higher than having THE perfect icon, but of course it still should look good)
 *
 * 4. We want to avoid import unused icons (delete unused icons proactively)
 *
 *
 * Adding fontawesome icons
 *
 * Just add another require statement to the `FONTAWESOME_ICONS` array. That's it! You can already use your icon (by
 * making use of the `Icon` component).
 *
 *
 * Adding custom icons
 *
 * We want to have as little as possible custom icons. Challenge the idea of adding another custom icon before
 * doing so. Also consider combining fontawesome icons by creating stacked icons! If you are still convinced,
 * you need to add another custom icon, make sure to simultaneously file an icon request on the fontawesome
 * github repository (in order the get this icon added to the fontawesome library, so we can remove that custom
 * icon again!).
 *
 * To add a custom icon, just drop the svg file into the `app/images/icons` folder. You'll be able to import it by
 * adding it to the `FONTAWESOME_ICONS` array, using the `requireCustomIcon` function.
 *
 * IMPORTANT! The fontawesome library only understands very simple icons. It will use the `viewBox`, defined on the
 * root `svg` element and the first `path` of your svg file. Nothing else! Therefore custom svg icons must only
 * contain 1 element called `path` (besides the root `svg` element). Take an example of already existing custom icons!
 * If you have more complex svg files, you can easily merge different layers and paths with very basic svg tools
 * like `Inkscape` or ask the designer to do so.
 *
 *
 * Naming *
 *
 * Please study the fontawesome docs to understand how icons are named. The names of custom icons must follow the
 * same naming principles!
 *
 */

import {
    IconDefinition,
    IconLookup,
    IconName,
    IconPrefix,
} from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import isDataURI from 'validator/lib/isDataURI';
import React from 'react';

import { splitDataUri } from '@@utils/URL';

type UnityIconName = string;

type IconImport = { definition: IconDefinition };
type IconStackIcon = { icon: IconImport; params?: Omit<FontAwesomeIconProps, 'icon'> };
type IconStackOptions = { squareViewBox?: boolean };
type IconStack = [UnityIconName, IconStackIcon[], IconStackOptions?];
type FontawesomeIcons = Array<IconImport | IconStack>;

const isIconImport = (value): value is IconImport =>
    typeof value === 'object' && isIconDefinition(value.definition);

const isIconDefinition = (value): value is IconDefinition =>
    typeof value === 'object' &&
    typeof value.prefix === 'string' &&
    typeof value.iconName === 'string' &&
    Array.isArray(value.icon) &&
    value.icon.length === 5;

const isIconStackIcon = (value): value is IconStackIcon =>
    typeof value === 'object' &&
    isIconImport(value.icon) &&
    (typeof value.params === 'undefined' || typeof value.params === 'object');

const isIconStack = (value): value is IconStack =>
    Array.isArray(value) &&
    value.length === 3 &&
    typeof value[0] === 'string' &&
    Array.isArray(value[1]) &&
    value[1].every((value) => isIconImport(value) || isIconStackIcon(value)) &&
    (typeof value[2] === 'undefined' || typeof value[2] === 'object');

// `solid` style and `classic` family is the default, that's why they do not appear in the unity icon name.
// `unityIconNameToFaIconName` relies on the order of this map to work correctly. Look at it and you will
// understand why.
const faPrefixToUnitySuffix = new Map<IconPrefix, string>([
    ['far', '-regular'],
    ['fal', '-light'],
    ['fat', '-thin'],
    ['fad', '-duotone'],
    ['fab', '-brands'],
    ['fak', '-kit'],
    ['fass', '-sharp'],
    ['fasr', '-sharp-regular'],
    ['fasl', '-sharp-light'],
]);

const unityIconNameToFaIconName = (unityIconName: UnityIconName): IconLookup => {
    let prefix: IconPrefix = 'fas';
    let iconName = unityIconName;

    faPrefixToUnitySuffix.forEach((unitySuffix, faPrefix) => {
        if (unityIconName.endsWith(unitySuffix)) {
            prefix = faPrefix;
            iconName = unityIconName.substring(0, unityIconName.length - unitySuffix.length);
        }
    });

    return { prefix, iconName: iconName as IconName };
};

const faIconNameToUnityIconName = ({ prefix, iconName }: IconLookup): UnityIconName => {
    const suffix = faPrefixToUnitySuffix.get(prefix) ?? '';

    return iconName + suffix;
};

export const resolveIcon = (value: FontawesomeIcons[number]) => {
    let unityIconName: UnityIconName;

    if (isIconImport(value)) {
        unityIconName = faIconNameToUnityIconName(value.definition);
    } else if (isIconStack(value)) {
        unityIconName = value[0];
    } else {
        unityIconName = value;
    }

    const faIconName = unityIconNameToFaIconName(unityIconName);

    return { ...faIconName, id: `icon-${unityIconName}` };
};

export const calculateViewBox = (icons: IconStackIcon[], options: IconStackOptions = {}) => {
    const { squareViewBox = false } = options;

    let maxWidth = 0;
    let maxHeight = 0;

    icons.forEach(
        ({
            icon: {
                definition: {
                    icon: [width, height],
                },
            },
        }) => {
            maxWidth = Math.max(width, maxWidth);
            maxHeight = Math.max(height, maxHeight);
        },
    );

    if (squareViewBox) {
        maxWidth = maxHeight = Math.max(maxWidth, maxHeight);
    }

    return [0, 0, maxWidth, maxHeight].join(' ');
};

const requireCustomIcon = (unityIconName: UnityIconName): IconImport => {
    const svgDataUri = require('../../../images/icons/' + unityIconName + '.svg');

    if (isDataURI(svgDataUri)) {
        const { data } = splitDataUri(svgDataUri);

        const svgXml = window.atob(data);

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(svgXml, 'text/xml');

        const viewBox = xmlDoc.querySelector('svg')?.getAttribute('viewBox');

        const path = xmlDoc.querySelector('path')?.getAttribute('d');

        if (viewBox && path) {
            const { prefix, iconName } = unityIconNameToFaIconName(unityIconName);
            const [, , width, height] = viewBox.split(' ').map(parseFloat);
            const aliases = [];
            const unicode = '';

            const definition = {
                prefix,
                iconName,
                icon: [width, height, aliases, unicode, path],
            } as IconDefinition;

            return { definition };
        }
    }

    throw new Error(`Was not able to load custom icon "${unityIconName}"!`);
};

const createStackedIcon = (
    unityIconName: UnityIconName,
    stack: (IconImport | IconStackIcon)[],
    options?: IconStackOptions,
): IconStack => [
    unityIconName,
    stack.map((icon) => (isIconStackIcon(icon) ? icon : { icon })),
    options,
];

export const FONTAWESOME_ICONS: FontawesomeIcons = [
    requireCustomIcon('document-media'),
    requireCustomIcon('document-star'),
    require('@fortawesome/sharp-solid-svg-icons/faPlus'),
    require('@fortawesome/sharp-solid-svg-icons/faXmark'),
    require('@fortawesome/sharp-solid-svg-icons/faMinus'),
    require('@fortawesome/sharp-solid-svg-icons/faCheck'),
    require('@fortawesome/pro-solid-svg-icons/faEye'),
    require('@fortawesome/pro-solid-svg-icons/faEyeSlash'),
    require('@fortawesome/pro-regular-svg-icons/faEye'),
    require('@fortawesome/pro-regular-svg-icons/faEyeSlash'),
    require('@fortawesome/sharp-solid-svg-icons/faCircleCheck'),
    require('@fortawesome/sharp-solid-svg-icons/faCircleExclamation'),
    require('@fortawesome/sharp-solid-svg-icons/faCircleQuestion'),
    require('@fortawesome/pro-regular-svg-icons/faCircleXmark'),
    require('@fortawesome/pro-solid-svg-icons/faGear'),
    require('@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare'),
    require('@fortawesome/pro-solid-svg-icons/faQuoteRight'),
    require('@fortawesome/pro-solid-svg-icons/faTag'),
    require('@fortawesome/pro-solid-svg-icons/faTags'),
    require('@fortawesome/pro-solid-svg-icons/faStar'),
    require('@fortawesome/pro-solid-svg-icons/faSitemap'),
    require('@fortawesome/pro-solid-svg-icons/faUser'),
    require('@fortawesome/pro-solid-svg-icons/faUsers'),
    require('@fortawesome/pro-solid-svg-icons/faUsersGear'),
    require('@fortawesome/sharp-solid-svg-icons/faRotate'),
    require('@fortawesome/sharp-solid-svg-icons/faRotateLeft'),
    require('@fortawesome/sharp-solid-svg-icons/faChevronUp'),
    require('@fortawesome/sharp-solid-svg-icons/faChevronDown'),
    require('@fortawesome/sharp-solid-svg-icons/faChevronLeft'),
    require('@fortawesome/sharp-solid-svg-icons/faChevronRight'),
    require('@fortawesome/sharp-regular-svg-icons/faChevronRight'),
    require('@fortawesome/pro-solid-svg-icons/faEllipsis'),
    require('@fortawesome/pro-solid-svg-icons/faEllipsisVertical'),
    require('@fortawesome/pro-regular-svg-icons/faPen'),
    require('@fortawesome/pro-solid-svg-icons/faXmark'),
    require('@fortawesome/pro-solid-svg-icons/faTrashCan'),
    require('@fortawesome/pro-solid-svg-icons/faMagnifyingGlass'),
    require('@fortawesome/pro-regular-svg-icons/faMagnifyingGlassPlus'),
    require('@fortawesome/sharp-regular-svg-icons/faBold'),
    require('@fortawesome/sharp-regular-svg-icons/faItalic'),
    require('@fortawesome/sharp-regular-svg-icons/faUnderline'),
    require('@fortawesome/sharp-regular-svg-icons/faCode'),
    require('@fortawesome/sharp-regular-svg-icons/faSubscript'),
    require('@fortawesome/sharp-regular-svg-icons/faSuperscript'),
    require('@fortawesome/sharp-regular-svg-icons/faDollarSign'),
    createStackedIcon('chevron-left-chevron-right-sharp', [
        {
            icon: require('@fortawesome/sharp-solid-svg-icons/faChevronLeft'),
            params: {
                transform: 'shrink-7 left-5',
            },
        },
        {
            icon: require('@fortawesome/sharp-solid-svg-icons/faChevronRight'),
            params: {
                transform: 'shrink-7 right-5',
            },
        },
    ]),
    createStackedIcon('chevrons-left-chevrons-right-sharp', [
        {
            icon: require('@fortawesome/sharp-solid-svg-icons/faChevronsLeft'),
            params: {
                transform: 'shrink-9 left-4.5',
            },
        },
        {
            icon: require('@fortawesome/sharp-solid-svg-icons/faChevronsRight'),
            params: {
                transform: 'shrink-9 right-4.5',
            },
        },
    ]),
    require('@fortawesome/sharp-solid-svg-icons/faDash'),
    requireCustomIcon('subscript-2-sharp-regular'),
    requireCustomIcon('superscript-2-sharp-regular'),
    requireCustomIcon('superscript-3-sharp-regular'),
    require('@fortawesome/sharp-regular-svg-icons/faLinkSimple'),
    require('@fortawesome/sharp-regular-svg-icons/faLinkSimpleSlash'),
    require('@fortawesome/sharp-regular-svg-icons/faHyphen'),
    require('@fortawesome/sharp-regular-svg-icons/faListOl'),
    require('@fortawesome/sharp-regular-svg-icons/faListUl'),
    require('@fortawesome/pro-regular-svg-icons/faCalendarDays'),
    require('@fortawesome/pro-solid-svg-icons/faCircle'),
    require('@fortawesome/sharp-solid-svg-icons/faCrosshairs'),
    require('@fortawesome/sharp-solid-svg-icons/faClock'),
    require('@fortawesome/sharp-regular-svg-icons/faClock'),
    require('@fortawesome/pro-solid-svg-icons/faFutbol'),
    require('@fortawesome/pro-solid-svg-icons/faHourglassHalf'),
    require('@fortawesome/pro-solid-svg-icons/faPersonSwimming'),
    require('@fortawesome/pro-light-svg-icons/faHandFist'),
    require('@fortawesome/sharp-solid-svg-icons/faXmarkLarge'),
    require('@fortawesome/sharp-solid-svg-icons/faSquareCheck'),
    require('@fortawesome/sharp-solid-svg-icons/faCopy'),
    require('@fortawesome/sharp-light-svg-icons/faCopy'),
    require('@fortawesome/pro-solid-svg-icons/faCurlingStone'),
    createStackedIcon('f-1', [
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faF'),
            params: {
                transform: 'shrink-4 left-2.5',
            },
        },
        {
            icon: require('@fortawesome/pro-solid-svg-icons/fa1'),
            params: {
                transform: 'shrink-4 right-4.5',
            },
        },
    ]),
    createStackedIcon('goal-net-futbol', [
        require('@fortawesome/pro-solid-svg-icons/faGoalNet'),
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faCircle'),
            params: {
                color: 'white',
                transform: 'shrink-9 down-6',
            },
        },
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faFutbol'),
            params: {
                transform: 'shrink-9 down-6',
            },
        },
    ]),
    require('@fortawesome/pro-solid-svg-icons/faVolleyball'),
    require('@fortawesome/pro-solid-svg-icons/faPersonSnowboarding'),
    require('@fortawesome/pro-solid-svg-icons/faPersonSkiing'),
    require('@fortawesome/pro-regular-svg-icons/faBasketball'),
    require('@fortawesome/pro-solid-svg-icons/faHorse'),
    require('@fortawesome/pro-regular-svg-icons/faCardsBlank'),
    require('@fortawesome/pro-solid-svg-icons/faSwords'),
    require('@fortawesome/pro-solid-svg-icons/faHockeySticks'),
    require('@fortawesome/pro-solid-svg-icons/faPersonBiking'),
    require('@fortawesome/pro-solid-svg-icons/faPersonSkating'),
    require('@fortawesome/pro-solid-svg-icons/faPersonSkiingNordic'),
    createStackedIcon('beach-volleyball', [
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faVolleyball'),
            params: { transform: 'shrink-3 up-2' },
        },
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faWater'),
            params: { transform: 'shrink-2 down-11' },
        },
    ]),
    require('@fortawesome/pro-solid-svg-icons/faTrophy'),
    createStackedIcon('racquet-tennis-ball', [
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faRacquet'),
            params: { transform: 'shrink-3 left-1 up-1' },
        },
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faTennisBall'),
            params: { transform: 'shrink-11.5 right-4 down-4.5' },
        },
    ]),
    require('@fortawesome/pro-solid-svg-icons/faPersonRunning'),
    require('@fortawesome/pro-solid-svg-icons/faGolfFlagHole'),
    require('@fortawesome/pro-light-svg-icons/faHandPointUp'),
    require('@fortawesome/sharp-regular-svg-icons/faArrowsRepeat'),
    require('@fortawesome/free-brands-svg-icons/faYoutube'),
    require('@fortawesome/free-brands-svg-icons/faFacebook'),
    require('@fortawesome/free-brands-svg-icons/faXTwitter'),
    require('@fortawesome/free-brands-svg-icons/faInstagram'),
    require('@fortawesome/free-brands-svg-icons/faTiktok'),
    require('@fortawesome/free-brands-svg-icons/faThreads'),
    requireCustomIcon('zattoo-brands'),
    require('@fortawesome/pro-regular-svg-icons/faImage'),
    require('@fortawesome/pro-solid-svg-icons/faThumbtack'),
    require('@fortawesome/pro-solid-svg-icons/faPaperPlane'),
    require('@fortawesome/pro-regular-svg-icons/faChartNetwork'),
    require('@fortawesome/pro-regular-svg-icons/faGrid2'),
    require('@fortawesome/pro-solid-svg-icons/faBoltLightning'),
    createStackedIcon('rectangle-regular-play', [
        require('@fortawesome/pro-regular-svg-icons/faRectangle'),
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faPlay'),
            params: { transform: 'shrink-10 right-0.5' },
        },
    ]),
    require('@fortawesome/pro-regular-svg-icons/faImages'),
    require('@fortawesome/pro-solid-svg-icons/faCode'),
    require('@fortawesome/pro-solid-svg-icons/faInbox'),
    require('@fortawesome/pro-solid-svg-icons/faFlag'),
    require('@fortawesome/pro-solid-svg-icons/faPlug'),
    require('@fortawesome/pro-solid-svg-icons/faFaceSmile'),
    require('@fortawesome/pro-solid-svg-icons/faFaceFrownSlight'),
    require('@fortawesome/pro-solid-svg-icons/faFaceSadTear'),
    require('@fortawesome/pro-solid-svg-icons/faFaceSadCry'),
    require('@fortawesome/pro-solid-svg-icons/faWindowMinimize'),
    require('@fortawesome/pro-regular-svg-icons/faSquarePlus'),
    require('@fortawesome/pro-regular-svg-icons/faClipboard'),
    require('@fortawesome/pro-regular-svg-icons/faCopy'),
    require('@fortawesome/pro-regular-svg-icons/faClone'),
    require('@fortawesome/sharp-solid-svg-icons/faArrowRightArrowLeft'),
    require('@fortawesome/pro-solid-svg-icons/faNewspaper'),
    require('@fortawesome/pro-solid-svg-icons/faMinus'),
    require('@fortawesome/pro-solid-svg-icons/faPuzzlePiece'),
    require('@fortawesome/pro-solid-svg-icons/faClipboardList'),
    require('@fortawesome/pro-solid-svg-icons/faPuzzlePieceSimple'),
    require('@fortawesome/sharp-solid-svg-icons/faSortUp'),
    require('@fortawesome/sharp-solid-svg-icons/faSortDown'),
    require('@fortawesome/pro-light-svg-icons/faFaceFrownSlight'),
    require('@fortawesome/pro-solid-svg-icons/faSquarePollVertical'),
    require('@fortawesome/pro-regular-svg-icons/faMessage'),
    require('@fortawesome/pro-solid-svg-icons/faThumbsUp'),
    require('@fortawesome/pro-solid-svg-icons/faThumbsDown'),
    require('@fortawesome/pro-solid-svg-icons/faRotate'),
    createStackedIcon('embed-sharp', [
        {
            icon: require('@fortawesome/sharp-solid-svg-icons/faAngleLeft'),
            params: {
                transform: 'shrink-6 left-4',
            },
        },
        {
            icon: require('@fortawesome/sharp-solid-svg-icons/faAngleRight'),
            params: {
                transform: 'shrink-6 right-4',
            },
        },
    ]),
    createStackedIcon('square-regular-play', [
        require('@fortawesome/pro-regular-svg-icons/faSquare'),
        {
            icon: require('@fortawesome/sharp-solid-svg-icons/faPlay'),
            params: { transform: 'shrink-10 right-0.5' },
        },
    ]),
    require('@fortawesome/pro-solid-svg-icons/faSquareInfo'),
    require('@fortawesome/pro-solid-svg-icons/faGripDotsVertical'),
    require('@fortawesome/pro-solid-svg-icons/faAlignLeft'),
    require('@fortawesome/sharp-regular-svg-icons/faCommentLines'),
    createStackedIcon('aspect-ratio', [
        require('@fortawesome/pro-regular-svg-icons/faExpand'),
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faUpRightAndDownLeftFromCenter'),
            params: { transform: 'shrink-7 rotate-90' },
        },
    ]),
    require('@fortawesome/pro-solid-svg-icons/faScrewdriverWrench'),
    require('@fortawesome/pro-solid-svg-icons/faPaperclip'),
    createStackedIcon('content-status-draft', [
        require('@fortawesome/pro-solid-svg-icons/faCircle'),
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faPen'),
            params: { color: 'white', transform: 'shrink-6' },
        },
    ]),
    createStackedIcon(
        'content-status-review-1',
        [
            require('@fortawesome/pro-solid-svg-icons/faCircle'),
            {
                icon: require('@fortawesome/pro-solid-svg-icons/faEye'),
                params: { color: 'white', transform: 'shrink-4' },
            },
        ],
        { squareViewBox: true },
    ),
    createStackedIcon(
        'content-status-seo',
        [
            require('@fortawesome/pro-solid-svg-icons/faCircle'),
            {
                icon: require('@fortawesome/sharp-regular-svg-icons/faListCheck'),
                params: { color: 'white', transform: 'shrink-6' },
            },
        ],
        { squareViewBox: true },
    ),
    createStackedIcon(
        'content-status-review-2',
        [
            require('@fortawesome/pro-solid-svg-icons/faCircle'),
            {
                icon: require('@fortawesome/pro-solid-svg-icons/faEye'),
                params: { color: 'white', transform: 'shrink-8 left-2.5 up-2.5' },
            },
            {
                icon: require('@fortawesome/pro-solid-svg-icons/faPen'),
                params: { color: 'white', transform: 'shrink-6 right-2 down-2' },
            },
        ],
        { squareViewBox: true },
    ),
    createStackedIcon('content-status-proofread', [
        require('@fortawesome/pro-solid-svg-icons/faCircle'),
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faMagnifyingGlass'),
            params: { color: 'white', transform: 'shrink-5' },
        },
    ]),
    createStackedIcon('content-status-required-rework', [
        require('@fortawesome/pro-solid-svg-icons/faCircle'),
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faExclamation'),
            params: { color: 'white', transform: 'shrink-3.5' },
        },
    ]),
    createStackedIcon('content-status-ready-to-publish', [
        require('@fortawesome/pro-solid-svg-icons/faCircle'),
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faCheck'),
            params: { color: 'white', transform: 'shrink-3.5 down-0.5' },
        },
    ]),
    createStackedIcon('publication-status-not-published', [
        require('@fortawesome/pro-solid-svg-icons/faCircle'),
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faXmark'),
            params: { color: 'white', transform: 'shrink-3.5' },
        },
    ]),
    createStackedIcon('publication-status-published', [
        require('@fortawesome/pro-solid-svg-icons/faCircle'),
        {
            icon: require('@fortawesome/pro-solid-svg-icons/faCheck'),
            params: { color: 'white', transform: 'shrink-3.5 down-0.5' },
        },
    ]),
    createStackedIcon('publication-status-unpublished', [
        require('@fortawesome/pro-solid-svg-icons/faCircle'),
        {
            icon: requireCustomIcon('check-slash'),
            params: { color: 'white', transform: 'shrink-3.5 down-0.5' },
        },
    ]),
    require('@fortawesome/sharp-solid-svg-icons/faImageLandscape'),
    require('@fortawesome/pro-solid-svg-icons/faLock'),
    require('@fortawesome/pro-solid-svg-icons/faBan'),
    require('@fortawesome/pro-solid-svg-icons/faPenClip'),
    require('@fortawesome/pro-solid-svg-icons/faSpellCheck'),
    require('@fortawesome/pro-regular-svg-icons/faNoteSticky'),
    require('@fortawesome/pro-solid-svg-icons/faArrowsDownToLine'),
    require('@fortawesome/pro-solid-svg-icons/faGripLines'),
    require('@fortawesome/pro-solid-svg-icons/faHighlighter'),
    require('@fortawesome/pro-solid-svg-icons/faCircleInfo'),
    requireCustomIcon('spellcheck-suggest'),
    require('@fortawesome/pro-regular-svg-icons/faFileCirclePlus'),
    require('@fortawesome/pro-regular-svg-icons/faRobot'),
    require('@fortawesome/pro-solid-svg-icons/faEnvelope'),
    require('@fortawesome/pro-solid-svg-icons/faTriangleExclamation'),
    require('@fortawesome/pro-regular-svg-icons/faLanguage'),
    requireCustomIcon('bernand'),
    require('@fortawesome/sharp-solid-svg-icons/faRectangleList'),
];

if (process.env.NODE_ENV === 'development') {
    const duplicatesCache = {};

    FONTAWESOME_ICONS.forEach((icon) => {
        const { id } = resolveIcon(icon);

        if (duplicatesCache[id]) {
            throw new Error(`You have defined "${id}" twice, which is not allowed!`);
        }

        duplicatesCache[id] = true;
    });
}

const Icons = ({ children }) => (
    <>
        {FONTAWESOME_ICONS.map((icon) => {
            const { id } = resolveIcon(icon);

            if (isIconImport(icon)) {
                return <FontAwesomeIcon key={id} symbol={id} icon={icon.definition} />;
            } else if (isIconStack(icon)) {
                const [, stack, options] = icon;
                const viewBox = calculateViewBox(stack, options);

                return (
                    <svg key={id} style={{ display: 'none' }}>
                        <symbol id={id} viewBox={viewBox}>
                            {stack.map(({ icon, params }, index) => (
                                <FontAwesomeIcon key={index} icon={icon.definition} {...params} />
                            ))}
                        </symbol>
                    </svg>
                );
            }

            throw new Error(`No icon definition found for: ${JSON.stringify(icon)}`);
        })}

        {children}
    </>
);

export default Icons;
