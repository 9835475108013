import { DropTargetMonitor, useDrop } from 'react-dnd';
import { Path, Range, Transforms } from 'slate';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { SLATE_ATTRIBUTE_SELECTORS } from '@@editor/constants';
import { TYPES } from '@@constants/DragAndDrop';
import { getNextElementSibling, getPreviousElementSibling } from '@@utils/DOM';
import { HorizontalLineType } from '@@editor/components/HorizontalLine';
import { ReactEditor, Editor, Element } from '@@editor/helpers';
import crypto from '@@utils/crypto';
import useDocumentDragHandlers from '@@hooks/useDocumentDragHandlers';

import { getHoverDirection } from './utils';
import { useDropLineActions } from './DropLineContext';
import { DragItem, DropDirections } from './types';

const previousWithoutBoundary = (path: Path) => [...path.slice(0, -1), path[path.length - 1] - 1];

const isInternalItem = (editor: Editor, monitor: DropTargetMonitor<DragItem>) =>
    monitor.getItemType() === TYPES.EDITOR_ELEMENT && monitor.getItem().editorId === editor.id;

const isExternalItem = (editor: Editor, monitor: DropTargetMonitor<DragItem>) =>
    !isInternalItem(editor, monitor);

const resolveCoordinates = (editor, monitor, blockRef: React.RefObject<HTMLElement>) => {
    const item = monitor.getItem();

    if (!blockRef.current) {
        return {};
    }

    let element: HTMLElement | null = blockRef.current;
    let direction = getHoverDirection(item, monitor, blockRef);
    let dragNode, dragPath, dropNode, dropPath;

    if (isInternalItem(editor, monitor) && item.blockRef.current) {
        dragNode = ReactEditor.toSlateNode(editor, item.blockRef.current);
        dragPath = ReactEditor.findPath(editor, dragNode);
    }

    if (element.dataset.slateFloatingToolbar) {
        const prevElement = getPreviousElementSibling(element, SLATE_ATTRIBUTE_SELECTORS.BLOCKS);
        const nextElement = getNextElementSibling(element, SLATE_ATTRIBUTE_SELECTORS.BLOCKS);

        element = null;

        if (direction === DropDirections.TOP) {
            if (prevElement) {
                element = prevElement;
                direction = DropDirections.BOTTOM;
            } else if (nextElement) {
                element = nextElement;
            }
        }

        if (direction === DropDirections.BOTTOM) {
            if (nextElement) {
                element = nextElement;
                direction = DropDirections.TOP;
            } else if (prevElement) {
                element = prevElement;
            }
        }

        if (element) {
            dropNode = ReactEditor.toSlateNode(editor, element);
            dropPath = ReactEditor.findPath(editor, dropNode);
        }
    } else {
        dropNode = ReactEditor.toSlateNode(editor, element);
        dropPath = ReactEditor.findPath(editor, dropNode);
    }

    return { direction, dragPath, dropPath, element };
};

type Options = {
    blockRef: React.RefObject<HTMLElement>;
};

export const useDropBlock = (
    editor: Editor,
    { blockRef }: Options,
): ReturnType<typeof useDrop<DragItem, void, EmptyObject>> => {
    const { clearDropLine, setDropLine, setIsOverTarget } = useDropLineActions();
    const dataTransfer = useRef<DataTransfer | null>(null);
    const id = useMemo(() => crypto.randomUUID(), [blockRef.current]);

    const handleDocumentDrop = useCallback((e) => {
        dataTransfer.current = e.dataTransfer;
    }, []);

    const handleInternalDrop = useCallback(
        (item: DragItem, monitor: DropTargetMonitor<DragItem>) => {
            const { direction, dragPath, dropPath } = resolveCoordinates(editor, monitor, blockRef);

            if (!direction || !dragPath || !dropPath) {
                return;
            }

            ReactEditor.focus(editor);

            let to = dropPath;

            if (Path.isSibling(dragPath, dropPath) && Path.isBefore(dragPath, dropPath)) {
                if (direction === DropDirections.TOP) {
                    to = Path.previous(to);
                }
            } else {
                if (direction === DropDirections.BOTTOM) {
                    to = Path.next(to);
                }
            }

            Transforms.moveNodes(editor, {
                at: dragPath,
                to,
            });
        },
        [editor],
    );

    const handleExternalDrop = useCallback(
        (item: DragItem, monitor: DropTargetMonitor<DragItem>) => {
            const { direction, dropPath } = resolveCoordinates(editor, monitor, blockRef);

            if (!direction || !dropPath) {
                return;
            }

            ReactEditor.focus(editor);

            const to = direction === DropDirections.BOTTOM ? Path.next(dropPath) : dropPath;

            if (monitor.getItemType() === TYPES.EDITOR_ELEMENT) {
                Editor.insertElement(editor, Element.create(item.element), { at: to });
            } else {
                if (dataTransfer.current) {
                    // The `dataTransfer` object is not always available on the drag item. `react-dnd` only exposes it for
                    // native item types, therefore we try to access it through a captured native drop event handler
                    editor.insertData(dataTransfer.current, { at: to });
                }
            }
        },
        [editor],
    );

    useDocumentDragHandlers({
        onDocumentDrop: handleDocumentDrop,
    });

    const [{ isOver }, ...rest] = useDrop<DragItem, void, { isOver: boolean }>({
        accept: [
            NativeTypes.FILE,
            NativeTypes.URL,
            NativeTypes.HTML,
            NativeTypes.TEXT,
            TYPES.ATTACHMENT,
            TYPES.EDITOR_ELEMENT,
        ],
        collect: (monitor) => ({ isOver: monitor.isOver({ shallow: true }) }),
        canDrop: (item, monitor) => {
            // Only allow drop if this drop target is directly hovered. For example for inline images, there is also a
            // a drop zone within the image plugin, if that one is hovered, that one has priority.
            if (!monitor.isOver({ shallow: true })) {
                return false;
            }

            const { direction, dragPath, dropPath } = resolveCoordinates(editor, monitor, blockRef);

            if (!direction || !dropPath) {
                return false;
            }

            if (isInternalItem(editor, monitor)) {
                if (!dragPath || Path.equals(dragPath, dropPath)) {
                    return false;
                }

                // Prevent dragging whole element into itself
                if (dragPath.length === 1 && dragPath[0] === dropPath[0]) {
                    return false;
                }

                if (direction === DropDirections.TOP) {
                    if (Path.equals(dragPath, previousWithoutBoundary(dropPath))) {
                        return false;
                    }
                }

                if (direction === DropDirections.BOTTOM) {
                    if (Path.equals(dragPath, Path.next(dropPath))) {
                        return false;
                    }
                }
            }

            return true;
        },
        drop: (item, monitor) => {
            clearDropLine();

            // The `dataTransfer` object is not always available on the drag item. `react-dnd` only exposes it for
            // native item types, therefore we try to access it through a captured native drop event handler
            dataTransfer.current = item.dataTransfer ?? dataTransfer.current;

            if (isInternalItem(editor, monitor)) {
                handleInternalDrop(item, monitor);
            } else if (isExternalItem(editor, monitor)) {
                handleExternalDrop(item, monitor);
            }
        },
        hover(item, monitor) {
            if (!monitor.isOver({ shallow: true })) {
                return;
            }

            const { direction, element } = resolveCoordinates(editor, monitor, blockRef);

            if (!direction || !element || !monitor.canDrop()) {
                clearDropLine();
            } else {
                setDropLine({
                    element,
                    direction,
                    type: isExternalItem(editor, monitor) ? HorizontalLineType.ADD_FILE : undefined,
                });
            }

            if (direction && editor.selection && Range.isExpanded(editor.selection)) {
                ReactEditor.focus(editor);
            }
        },
    });

    useEffect(
        () => () => {
            // Whenever the id will be unset, we will remove the hover state for that id. Typically this will happen
            // when unmounting the component
            setIsOverTarget(id, false);
        },
        [id],
    );

    useEffect(() => {
        setIsOverTarget(id, isOver);

        return () => {
            // If this drop block is about to loose its hover state, we will remove the hover state for that id. This
            // will also be triggered when unmounting the component
            if (isOver) {
                setIsOverTarget(id, false);
            }
        };
    }, [isOver]);

    return [{}, ...rest];
};
