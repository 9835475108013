import { get, isEqual, omit } from 'lodash';

import { Editor } from '@@editor/helpers';

import { mergeData } from './utils';

const FLUSHING = new WeakMap<Editor, boolean>();
const FORCE_UPDATE = new WeakMap<Editor, boolean>();

const changeData = (editor: Editor, data) => {
    // eslint-disable-next-line no-param-reassign
    editor.data = data;

    if (!FLUSHING.get(editor)) {
        FLUSHING.set(editor, true);

        queueMicrotask(() => {
            FLUSHING.set(editor, false);
            editor.onChangeData();
            FORCE_UPDATE.set(editor, false);
        });
    }
};

const withData = (editor: Editor) =>
    Object.assign(editor, {
        data: {},
        getData: () => editor.data,
        getDataIn: (path) => get(editor.data, path),
        setData: (newData, shouldForceUpdate = true) => {
            const nextData = mergeData(editor.data, newData);

            if (!isEqual(editor.data, nextData)) {
                if (shouldForceUpdate) {
                    FORCE_UPDATE.set(editor, shouldForceUpdate);
                }

                changeData(editor, nextData);
            }
        },
        unsetData: (paths, shouldForceUpdate = true) => {
            if (shouldForceUpdate) {
                FORCE_UPDATE.set(editor, shouldForceUpdate);
            }

            changeData(editor, omit(editor.data, paths));
        },
        onChangeData: () => {
            if (FORCE_UPDATE.get(editor)) {
                // Some plugins need a force update, in order to read the data again and react accordingly
                // to it (for example showing a modal).
                editor.forceUpdate();
            }
        },
    });

export default withData;
